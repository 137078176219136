import React from "react";
import optionCallback from "./optionCallback";

export default optionCallback('frame21',({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1154 1318.637">
		<g>
			<g transform="translate(150 2)">
				<rect width="1000" height="1200" rx={30}  fill={backgroundColor} stroke={frameBody} strokeWidth="8" />
			</g>
			<g transform="translate(0 838)">
				<path d="M153.314,90.1,0,159.506H153.314Z" transform="translate(0 0)" fill={frameBody}/>
				<path d="M153.314,90.1,0,159.506H153.314Z" transform="translate(0 0)" fill="#fff" opacity="0.3"/>
				<path d="M0,96.9V392.133H0A24.862,24.862,0,0,0,24.862,417H1129.138A24.862,24.862,0,0,0,1154,392.133h0V96.9Z" transform="translate(0 63.642)" fill={frameBody}/>
			</g>
		</g>
		<g transform={"translate(154 0)"}><BaseQrcode /></g>
		<g transform={"translate(220 1080) scale(0.85)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)