import withProps from 'recompose/withProps';
import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame13', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1090 1576.337">
		<g>
			<rect fill={frameBody} width="1090" height="1084" rx="40"/>
			<rect fill={backgroundColor} x={-5} y={-5} width="992" height="986" rx="20" transform="translate(49 49)" />
			<path fill={frameBody} d="M1040.758,533.1H48.407C21.673,533.1,0,511.026,0,483.793V261.9c0-27.233,21.673-49.311,48.407-49.311H418.723a10.8,10.8,0,0,0,8.471-3.7L536.111,97.938a11.944,11.944,0,0,1,16.943,0L661.97,208.887a10.8,10.8,0,0,0,8.471,3.7h370.316c26.735,0,48.407,22.077,48.407,49.311v221.9C1089.165,511.026,1067.492,533.1,1040.758,533.1Z" transform="translate(0 1043.234)"/>
		</g>
		
		<g transform={"translate(45 43)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g transform={"translate(45,1310) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)