import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame14', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1114.445 1617.969">
		<g transform="translate(0 0)">
			<path fill={frameBody} d="M1046.578,12.3H993V82.548a94.061,94.061,0,1,1-186.931,0V12.3H647.712V82.548a94.061,94.061,0,1,1-186.931,0V12.3H302.424V82.548a94.061,94.061,0,1,1-186.931,0V12.3H67.867A67.869,67.869,0,0,0,0,78.987v979.891a69.057,69.057,0,0,0,67.867,67.867h978.711a69.058,69.058,0,0,0,67.867-67.867V78.976A67.867,67.867,0,0,0,1046.578,12.3Z" transform="translate(0 134.149)"/>
			<rect fill={frameBody} width="1114.445" height="1114.325" rx="57" transform="translate(0 503.643)" />
			<path fill={frameBody} d="M70.942,0A58.342,58.342,0,0,0,12.6,58.342V211.935a58.342,58.342,0,0,0,58.342,58.342,59.535,59.535,0,0,0,59.533-58.354V58.342A59.532,59.532,0,0,0,70.942,0Z" transform="translate(137.422 0)"/>
			<path fill={frameBody} d="M99.942,0A58.342,58.342,0,0,0,41.6,58.342V211.935a58.342,58.342,0,0,0,58.342,58.342,59.535,59.535,0,0,0,59.533-58.354V58.342A59.532,59.532,0,0,0,99.942,0Z" transform="translate(453.709 0)"/>
			<path fill={frameBody} d="M128.942,0A58.342,58.342,0,0,0,70.6,58.342V211.935a58.342,58.342,0,0,0,58.342,58.342,59.535,59.535,0,0,0,59.533-58.354V58.342A59.532,59.532,0,0,0,128.942,0Z" transform="translate(769.996 0)"/>
		</g>
		<g transform={"translate(57 563)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g transform={"translate(57 339) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)