import React from "react";
import PointBase from './PointBase';
import { QRCode } from '../qrcode';
import { pointOptions } from "./ultil";

export class RendererRect extends PointBase {
	/**
	 *
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		
		const pointRender = ($options: any) => {
			let { "width" : $width, "height" : $height, ...$opts} = pointOptions($options, ["opacity", "fill", "transform", "width", "height"], ['cx', 'cy'],
				function ($opts: any){
					let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
					$scale = $scale * 1;
					Object.assign($opts, { "transform": `translate(${$x},${$y}) scale(${$scale})` });
					return $opts;
				});
			
			return <g {...$opts}><rect {...{ "width" : 100, "height" : 100 }} /></g>;
		}
		
		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"size": 80,
		})
		
		super($options, $qrcode, $name || 'RendererRect');
	}
}
