import compose from 'recompose/compose';
import Circle from "./Circle";
import frame0 from "./frame0";
import frame1 from "./frame1";
import frame2 from "./frame2";
import frame3 from "./frame3";
import frame4 from "./frame4";
import frame5 from "./frame5";
import frame6 from "./frame6";
import frame7 from "./frame7";
import frame8 from "./frame8";
import frame9 from "./frame9";
import frame10 from "./frame10";
import frame11 from "./frame11";
import frame12 from "./frame12";
import frame13 from "./frame13";
import frame14 from "./frame14";
import frame15 from "./frame15";
import frame16 from "./frame16";
import frame17 from "./frame17";
import frame18 from "./frame18";
import frame19 from "./frame19";
import frame20 from "./frame20";
import frame21 from "./frame21";
import frame22 from "./frame22";
import frame23 from "./frame23";
import frame24 from "./frame24";
import Base from "./default";

export default compose(
	Circle,
	frame0,
	frame1,
	frame2,
	frame3,
	frame4,
	frame5,
	frame6,
	frame7,
	frame8,
	frame9,
	frame10,
	frame11,
	frame12,
	frame13,
	frame14,
	frame15,
	frame16,
	frame17,
	frame18,
	frame19,
	frame20,
	frame21,
	frame22,
	frame23,
	frame24,
	Base
)