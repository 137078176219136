import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";
/**
 * @QrProvider(
 *  id = "pointed_round",
 *  title = @Translation("Pointed round"),
 *  type = 10
 * )
 */
export class RendererPointedRound extends EdgeCutsBase{
	/**
	 * RendererPointedMooth
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		super({...$options, type: 10 }, $qrcode, $name || 'RendererPointedMooth')
	}
}
