import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";

/**
 * @QrProvider(
 *   id = "diamond",
 *   title = @Translation("Diamond")
 * )
 */
export class RendererDiamond extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "transform"], ['cx', 'cy'],
				function ($opts: any, $keys: string[]){
					let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
					$scale = $scale * 1;
					Object.assign($opts, { "transform": `translate(${$x},${$y}) scale(${$scale})` });
					return $opts;
			});
			
			return <g {...$opts}><polygon points="0,50 50,100 100,50 50,0"/></g>;
		}
		
		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"size": 100,
		})
		
		super($options, $qrcode, $name || 'RendererDiamond');
	}
}
