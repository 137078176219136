import React from "react";
import optionCallback from "./optionCallback";
import toPath from 'element-to-path';

export default optionCallback('circle', ({ circleColor, frameColor, color, svgProps, backgroundColor, BaseQrcode })=> {
	
	let $r = 480;
	
	let circleOptions = {
		"fill"				: circleColor,
		"stroke"			: frameColor || color || 'black',
		"strokeWidth"	: 30
	}
	
	const circle = {
		type: 'element',
		name: 'circle',
		attributes: {
			"r" 					: $r,
			"cx" 					: 500,
			"cy" 					: 500
		}
	}
	
	const path = toPath(circle)
	return (<svg xmlns="http://www.w3.org/2000/svg" {...svgProps} viewBox="0 0 1000 1000">
		<g>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<path d={path} {...circleOptions} />
			<BaseQrcode/>
		</g>
	</svg>)
})