import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame15', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" {...props} viewBox="0 0 1135.416 1739.307">
		<g transform="translate(0.018 0.063)">
			<g transform="translate(37.759 462.173)">
				<g>
					<path d="M4.3,896.877A68.209,68.209,0,0,0,71.143,964.1h965.125a67.041,67.041,0,0,0,65.671-67.221V40.7H4.3Z" transform="translate(-4.3 -40.7)" fill={frameBody}/>
					<rect width="1097.639" height="1103.713" rx="57" transform="translate(0 173.358)" fill={frameBody}/>
				</g>
				<g transform="translate(48.352 224.068)">
					<rect width="996.218" height="1002.883" rx="28" fill={backgroundColor}/>
				</g>
			</g>
			<g transform="translate(1.2 1.2)">
				<path id="Path_9" data-name="Path 9" d="M148.482,53.417,16.4,277.485l148.593-38.917L297.075,14.5Z" transform="translate(161.523 142.348)" fill={frameBody} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_10" data-name="Path 10" d="M172.682,48.2,40.6,272.265l159.206-41.276L331.889,8.1Z" transform="translate(420.551 73.272)" fill={frameBody} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_11" data-name="Path 11" d="M1.2,65.614,46.014,238.972,174.558,20.8Z" transform="translate(-1.2 210.344)" fill={frameBody} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_12" data-name="Path 12" d="M197.882,42.876,65.8,265.765l159.206-41.276L357.089,1.6Z" transform="translate(689.84 3.117)" fill={frameBody} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_13" data-name="Path 13" d="M132.276,11.5,91,82.258l55.427-14.152Z" transform="translate(961.825 109.969)" fill={frameBody} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_14" data-name="Path 14" d="M133.544,53.179,5,271.351l1.179,4.717,133.262-34.2L271.523,17.8Z" transform="translate(39.814 177.965)" fill={textColor} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_15" data-name="Path 15" d="M161.082,46.879,29,270.948l136.8-35.379L297.882,11.5Z" transform="translate(296.446 109.969)" fill={textColor} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_16" data-name="Path 16" d="M186.182,40.479,54.1,263.368l137.979-35.379L324.161,5.1Z" transform="translate(565.178 40.893)" fill={textColor} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
				<path id="Path_17" data-name="Path 17" d="M226.713,1.2,211.382,5.917,79.3,228.806l137.979-35.379,41.276-70.758Z" transform="translate(835.546 -1.2)" fill={textColor} stroke={frameBody} strokeMiterlimit="10" strokeWidth="2"/>
			</g>
		</g>
		
		<g transform={"translate(82 686)"}><BaseQrcode /></g>
		<g transform={"translate(160 495) scale(0.8)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)