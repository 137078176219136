import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame1', ({ props, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1251.924 1804.485">
		<g>
			<g transform={"translate(128,768)"}>
				<rect width="1000" height="1000" fill={backgroundColor}/>
				<BaseQrcode />
			</g>
			<g>
				<path fill={frameBody} d="M1196.788,362.467H915.328c283.389-99.475,250.616-168.876,250.616-168.876C1086.517,16.23,1041.406,8.519,1041.406,8.519,909.158-55.87,686.3,265.3,686.3,265.3v.386a118.8,118.8,0,0,0-121.067,0V265.3S342.38-55.87,210.132,8.519c0,0-45.5,7.711-124.537,185.07,0,0-32.773,69.4,250.616,168.876H54.75A54.713,54.713,0,0,0,0,417.216V638.529a48.051,48.051,0,0,0,47.81,47.81h40.1V1751.65a52.9,52.9,0,0,0,52.822,52.822h970.463a52.9,52.9,0,0,0,52.822-52.822V685.955h40.1a48.051,48.051,0,0,0,47.81-47.81V416.831a55.472,55.472,0,0,0-55.135-54.364Zm-73.643,1372.6a28.467,28.467,0,0,1-28.532,28.532H156.924a28.467,28.467,0,0,1-28.532-28.532V797.382a28.467,28.467,0,0,1,28.532-28.532h937.69a28.467,28.467,0,0,1,28.532,28.532Z" transform="translate(0 0.013)"/>
				<path fill={frameBody} d="M1098.522,552.756v42.027H22.8V552.756ZM367.494,229.268h75.184c-50.894-32-339.3-210.518-374-178.9C34.752,81.6,308.116,203.436,367.494,229.268Zm311.15,0c50.894-32,339.295-210.518,374-178.9C1086.568,81.6,813.2,203.441,754.213,229.269Zm-236.735,0c2.7-62.846,54.365-113.356,117.978-113.356s115.669,50.508,117.978,113.356Z" transform="translate(65.108 133.21)"/>
				<path fill="#fff" opacity="0.3" d="M1098.522,552.756v42.027H22.8V552.756ZM367.494,229.268h75.184c-50.894-32-339.3-210.518-374-178.9C34.752,81.6,308.116,203.436,367.494,229.268Zm311.15,0c50.894-32,339.295-210.518,374-178.9C1086.568,81.6,813.2,203.441,754.213,229.269Zm-236.735,0c2.7-62.846,54.365-113.356,117.978-113.356s115.669,50.508,117.978,113.356Z" transform="translate(65.108 133.21)"/>
			</g>
			<g transform={"translate(120,430)"}>
				<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
					<rect width={1000} height={200} fill={'none'} />
					<g fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
				</svg>
			</g>
		</g>
	</svg>)