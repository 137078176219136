import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";

/**
 * @QrProvider(
 *   id = "heart",
 *   title = @Translation("Heart")
 * )
 */
export class RendererHeart extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "transform"], [], function ($opts: any){
				let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
				$scale = $scale * 15;
				return Object.assign($opts, {
					"transform": `translate(${$x},${$y}) scale(${$scale})`
				})
			});
			
			return <g {...$opts}><path d="M6,1.8C5.9,1,5.3,0.4,4.5,0.3C3.9,0.2,3.4,0.5,3,0.9C2.6,0.5,2.1,0.3,1.6,0.3C0.8,0.4,0.1,1,0,1.8 C0,2.3,0.1,2.7,0.3,3l0,0l0,0c0.1,0.1,0.2,0.2,0.3,0.3l1.9,2.2c0.3,0.3,0.7,0.3,0.9,0l1.8-1.9c0.1-0.1,0.3-0.3,0.4-0.5 C5.9,2.8,6.1,2.3,6,1.8z"/></g>;
		}

		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"size": 100,
		})
		
		super($options, $qrcode, $name || 'RendererHeart');
	}
}
