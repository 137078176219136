import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame6', ({ props, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor, scale })=>
		<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1068.197 1384.332">
		<g transform={"translate(35,35)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g>
			<path fill={frameBody} data-name="Path 7" d="M930.415,1109.149H137.4c-75.78,0-137.4,61.62-137.4,137.782H0c0,75.78,61.619,137.4,137.4,137.4H930.032c75.78,0,137.4-61.619,137.4-137.4h0a137.045,137.045,0,0,0-137.017-137.782ZM1015.381,0H52.434A52.27,52.27,0,0,0,0,52.434v963.33A52.514,52.514,0,0,0,52.434,1068.2h963.33a52.514,52.514,0,0,0,52.434-52.434V52.434A52.842,52.842,0,0,0,1015.381,0Zm12.247,999.306a28.258,28.258,0,0,1-28.322,28.322H68.509a28.258,28.258,0,0,1-28.322-28.322V68.509A28.258,28.258,0,0,1,68.509,40.187h930.8a28.258,28.258,0,0,1,28.322,28.322Z"/>
			<path fill={frameBody} data-name="Path 8" d="M116.478,297.2A109.078,109.078,0,1,0,225.556,406.278,108.786,108.786,0,0,0,116.478,297.2Z" transform="translate(20.922 840.271)"/>
			<path fill={textColor} data-name="Path 9" d="M116.478,304.855A101.423,101.423,0,1,1,15.055,406.278,101.6,101.6,0,0,1,116.478,304.855m0-7.655A109.078,109.078,0,1,0,225.556,406.278,108.786,108.786,0,0,0,116.478,297.2Z" transform="translate(20.922 840.271)"/>
			<path fill={textColor} data-name="Path 10" d="M121.427,319.092V456.874a15.762,15.762,0,0,1-15.692,15.692H37.992A15.762,15.762,0,0,1,22.3,456.874V319.092A15.762,15.762,0,0,1,37.992,303.4h67.743A16.018,16.018,0,0,1,121.427,319.092Zm-70.039.765a7.267,7.267,0,0,0,7.272,7.272H84.685a7.267,7.267,0,0,0,7.272-7.272,7.528,7.528,0,0,0-7.272-7.272H59.042A7.358,7.358,0,0,0,51.387,319.857Zm58.175,17.988L33.4,337.08V438.886h76.163ZM61.338,455.726A10.816,10.816,0,0,0,71.672,466.06a10.334,10.334,0,0,0,0-20.667C66.314,445.01,61.338,449.985,61.338,455.726Z" transform="translate(63.049 857.8)"/>
		</g>
		
		<g transform={"translate(280,1180) scale(0.7)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)