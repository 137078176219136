import withProps from "recompose/withProps";
import { shiftOfObject } from "@gqlapp/base/utils/filterObject";

export function getStyle($options: any[], style:string){
	return shiftOfObject($options, function ($value: any, $key: string){
		return !!$value && [`${style}`, 'default'].includes($key);
	});
}

/**
 *
 * @param typeCallback
 * @param callback
 * @param options
 */
export default function optionCallback(typeCallback: string, callback: any, options?: any) {
	return withProps((props: any) => {
		let frames = props?.frames || {}
		
		frames[typeCallback] = Object.assign(
			{ name: typeCallback },
			{ callback, options: options || {}}
		)
		
		return { frames }
	});
}