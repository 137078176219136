import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame3', ({ props, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=> <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1440.172 1288.895">
		<g>
			<g>
				<rect width={995} height={1050} x={220} y={7} rx={40} fill={backgroundColor} stroke={frameBody} strokeWidth={10} />
				<path fill={frameBody} d="M1438.158,501.26l-39.09-88.84a59.061,59.061,0,0,1,0-49.04l38.735-88.84a20.725,20.725,0,0,0-19.19-29.14H1215.7V372.264H224.243V245.4H21.332c-14.925,0-25.231,15.636-19.19,29.14l38.734,88.84a61.9,61.9,0,0,1,0,49.04L1.787,501.26a21,21,0,0,0,19.19,29.495H122.965v73.2c0,32.338,26.3,58.279,57.924,58.279H1259.411c31.983,0,57.924-26.3,57.924-58.279v-73.2h101.989C1434.6,530.755,1444.2,515.119,1438.158,501.26Z" transform="translate(0 626.657)"/>
				<path id="shadowDarker" d="M1209.224,290.176c-2.488-1.422-81.022-44.776-81.022-44.776V359.471H136.389V245.4s-78.535,43.354-81.022,44.776A45.824,45.824,0,0,0,34.4,327.844a44.972,44.972,0,0,0,44.776,44.42h1106.24a44.741,44.741,0,0,0,44.776-44.42A45.825,45.825,0,0,0,1209.224,290.176Z" transform="translate(87.855 626.657)" opacity="0.35"/>
				<path id="ShadowDark" d="M1419.323,530.755H1318.046V327.844a45.824,45.824,0,0,0-20.966-37.668c-2.488-1.422-81.022-44.776-81.022-44.776h202.911a20.725,20.725,0,0,1,19.19,29.14l-38.734,88.84a59.059,59.059,0,0,0,0,49.04l39.09,88.84C1444.2,515.119,1434.6,530.755,1419.323,530.755Zm-1398.347,0H122.255V327.844a45.824,45.824,0,0,1,20.966-37.668c2.488-1.422,81.022-44.776,81.022-44.776H21.332a20.725,20.725,0,0,0-19.19,29.14l38.734,88.84a59.061,59.061,0,0,1,0,49.04L1.787,501.26A21,21,0,0,0,20.977,530.755Z" transform="translate(0 626.657)" opacity="0.15"/>
			</g>
		</g>
		
		<g transform={"translate(225 5)"}>
			<BaseQrcode />
		</g>
		<g transform={"translate(220 1050) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)