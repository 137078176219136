/* eslint-disable */
// Generated by gulp svg-icon - do not modify manually
import React, { PureComponent as Component } from 'react';
import PropTypes from 'prop-types';
import Base from "../frames";
import {getStyle} from "../frames/optionCallback";
import filterObject from "@gqlapp/base/utils/filterObject";

class Frames extends Component {
  
  static propTypes = {
    drawIcon: PropTypes.any,
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    kind: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    preview: PropTypes.bool,
    size: PropTypes.number,
    style: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.any
    ]),
    wrapperStyle: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object
    ])
  }
  
  static defaultProps = {
    opacity: 1,
    scale: 1,
    width: '100%',
    height: '100%'
  }
  
  render() {
    
    // @ts-ignore
    let opacity = this.props?.opacity, kind = this.props?.kind || 'default';
    // @ts-ignore
    let children = this.props?.children, frames = this.props?.frames;
    
    function BaseQrcode() {
      return <svg xmlns="http://www.w3.org/2000/svg"
                  {...{ opacity, width: 1000, height: 1000 }}
                  {...{ viewBox: '0 0 1000 1000' }}>
        {children}
      </svg>
    }
    
    let propKeys: string[] = 'backgroundColor, opacity, style, scale , color, font, icon, iconColor'.split(',')
    propKeys = propKeys.concat('frameBody, viewBox, transform, textColor, shadowDark, shadowDarker, text, circleColor'.split(','))
    propKeys = propKeys.map(value => `${value}`.trim());
    
    
    let props = filterObject(this.props, function ({}, key: any){
      return propKeys.includes(key)
    })
    
    let svgProps = filterObject(this.props, function ({}, key: any){
      return !(propKeys.includes(key) || ['drawIcon', 'children', 'frames', 'kind'].includes(key))
    })
    
    Object.assign(props, { BaseQrcode, svgProps })
    // debug('FRAMES', propKeys, props);
    let dataStyle: any;
    
    if((dataStyle = getStyle(frames, kind))){
      return func(dataStyle?.callback)(props)
    }
    
    return <></>
  }
  
  Image(props){
  
  }
}


export default Base(Frames);
