import React from "react";
import optionCallback from "./optionCallback";

export default optionCallback('frame10', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1372.017 1900">
		<g transform={"translate(47,647)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g fill={frameBody}>
			<path
				d="M1370.946,925.885V267.973L1173.1,448.255H1094.67V118.128A66.73,66.73,0,0,0,1029.1,51.4H65.657A66.728,66.728,0,0,0,.1,118.128v963.456a65.557,65.557,0,0,0,65.557,65.557h963.456a65.557,65.557,0,0,0,65.557-65.557V753.8h85.458ZM1009.212,1096.8H79.705a31.608,31.608,0,0,1-32.779-32.778V134.517a32.779,32.779,0,0,1,32.779-32.778h929.506a33.949,33.949,0,0,1,32.778,32.779v929.506A32.778,32.778,0,0,1,1009.212,1096.8Z"
				transform="translate(1.071 550.321)"/>
			<circle cx="228.279" cy="228.279" r="228.279" transform="translate(0 162.722)"/>
			<circle cx="309.055" cy="309.055" r="309.055" transform="translate(475.289)"/>
		</g>
		<g transform={"translate(50,1730)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={'100%'} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={frameBody} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)