import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame11', ({ props, scale, backgroundColor, BaseQrcode, opacity, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1547 1481">
		<g transform={"translate(547 0)"}>
			<svg opacity={opacity} xmlns="http://www.w3.org/2000/svg" width={1000} height={1000} viewBox="0 0 1000 1000">
				<g transform={`translate(10, 10)`}>
					<rect width={980} height={1050} rx={40} strokeWidth={20} stroke={backgroundColor} fill={backgroundColor}/>
					<rect width={980} height={1050} rx={40} strokeWidth={20} stroke={frameBody} style={{ fill: 'none' }} fill={'none'}/>
					<rect width={980} height={1050} rx={40} strokeWidth={20} stroke={'#fff'} opacity={0.7} style={{ fill: 'none' }} fill={'none'}/>
				</g>
				<BaseQrcode />
			</svg>
		</g>
		
		<g transform="translate(0,710)">
			<path fill={frameBody} d="M1267.25,85H29.7V477.107a32.97,32.97,0,0,0,34.147,32.97h1203.4a31.793,31.793,0,0,0,32.97-32.97V85Z" transform="translate(246.758 184.252)"/>
			
			<g transform="translate(0 117.355)">
				<path fill={frameBody} d="M551.149,368.829V72.1H245L18.92,297c-4.71,5.887-32.97,42.39,14.13,88.312L276.792,632.589c8.243,8.243,15.307,18.84,48.277,16.485a35.325,35.325,0,0,1-27.082-9.42c-4.71-5.888-7.065-12.953,7.065-25.9Z" transform="translate(-6.222 -72.1)"/>
				<path d="M551.149,368.829V72.1H245L18.92,297c-4.71,5.887-32.97,42.39,14.13,88.312L276.792,632.589c8.243,8.243,15.307,18.84,48.277,16.485a35.325,35.325,0,0,1-27.082-9.42c-4.71-5.888-7.065-12.953,7.065-25.9Z" transform="translate(-6.222 -72.1)" fill="#fff" opacity="0.3"/>
			</g>
			<g id="Group_30" data-name="Group 30" transform="translate(158.119 244.525)">
				<path fill={textColor} d="M73.887,87.2h25.9v52.987H152.78V167.27H99.792v52.987h-25.9V167.27H20.9V140.187H73.887Z" transform="translate(-20.9 -36.568)"/>
				<path fill={textColor} d="M34.4,113.515c24.727,0,49.455-8.242,52.987-30.615h24.727V266.59H79.145V137.065H34.4Z" transform="translate(124.562 -82.9)"/>
			</g>
		</g>
		
		<g transform={"translate(640,1100) scale(0.82)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)