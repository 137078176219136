/**
 *
 * @param obj
 * @param callback
 */
export function filterObject(obj:any, callback: any) {
	return Object.fromEntries(Object.entries(obj).filter(([key, val]: any) => callback(val, key)));
}

/**
 *
 * @param obj
 * @param callback
 */
export function shiftOfObject(obj: any, callback: any){
	try{
		let data = filterObject(obj, callback);
		return data[Object.keys(data).shift()];
	}catch (e){
		return {}
	}
}
export default filterObject;