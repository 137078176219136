import { QRCode } from '../qrcode'
import LineBase from "./LineBase";

/**
 * @QrProvider(
 *   id = "line_2",
 *   title = @Translation("Line 2"),
 *   type = 2
 * )
 */
export class RendererLine2 extends LineBase{
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		super({...$options, type: 2 }, $qrcode, $name || 'RendererLine2')
	}
}
