import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";


/**
 * @QrProvider(
 *   id = "dot_random",
 *   title = @Translation("Dot random")
 * )
 */
export class RendererDotRandom extends PointBase {

	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		
		function pos($options: any){
			let $opts = pointOptions($options, ["opacity", "fill", "cx", "cy", "r" ], [],
				function ($opts: any){
					let { "r-random": $r } = $opts || {};
				return Object.assign($opts, { r: $r });
			});
			
			return <circle {...$opts} />;
		}
		
		function align($options: any){
			let $opts = pointOptions($options, ["opacity", "fill", "cx", "cy", "r"]);
			return <circle {...$opts} />;
		}
		
		Object.assign($options, {
			"pos": pos,
			"align": align,
			"stroke": $options?.dataStroke || "#ffffff80",
			"strokeWidth": 8
		})
		
		super($options, $qrcode, $name || 'RendererDotRandom');
	}

}
