import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame16',({ props, scale, backgroundColor, BaseQrcode, frameBody, viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1115.017 1522.95">
		<path fill={backgroundColor} d="M1115.017,1169.408H0V54.391A54.391,54.391,0,0,1,54.391,0H1060.626a54.391,54.391,0,0,1,54.391,54.391Z" />
		<g transform="translate(0 1091.265)">
			<path fill={frameBody} d="M1060.626,511.938H54.391A54.391,54.391,0,0,1,0,457.547V158.4H439.208a21.758,21.758,0,0,0,16.317-5.439L549.35,82.249a17.677,17.677,0,0,1,16.317,0l93.825,70.708a21.758,21.758,0,0,0,16.317,5.439h439.208V457.547A54.391,54.391,0,0,1,1060.626,511.938Z" transform="translate(0 -80.253)" />
		</g>
		<g transform={"translate(55 62)"}><BaseQrcode /></g>
		<g transform={"translate(28 1257) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)