import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "RendererLeaf",
 *   title = @Translation("Leaf"),
 *   type = 4
 * )
 */
export class RendererLeaf extends EdgeCutsBase{
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		super({...$options, type: 4 }, $qrcode, $name || 'RendererLeaf');
	}
}
