import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame24', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text })=>
	<>
		<svg xmlns="http://www.w3.org/2000/svg" {...props}  viewBox="0 0 1270 1260">
			<g>
				<g transform="translate(0 10)">
					<path fill={frameBody}  d="M152.914,297.226h0c.708-12.035,1.77-24.424,2.832-36.459,1.416-12.035,2.832-24.07,4.956-35.751s4.6-23.362,7.433-34.689c3.186-10.973,6.725-21.946,11.327-30.8h0c.354-1.062.708-2.478,1.062-3.894a20.679,20.679,0,0,0-16.282-24.424l-1.062-.354a10.919,10.919,0,0,0-3.186-.354c-1.062,0-1.77-.354-2.832-.354-1.77,0-3.54-.354-5.309-.354-3.54,0-7.079.354-10.619.354-7.079.708-13.8,1.416-20.53,2.478a357.769,357.769,0,0,0-39.644,8.5,337.226,337.226,0,0,0-37.52,13.451c-6.017,2.478-12.035,5.663-18.052,8.141-6.017,2.832-11.681,6.371-17.344,9.2-1.77,1.062-2.832,2.832-2.124,4.956a4.191,4.191,0,0,0,5.31,3.186h.354c6.371-1.416,12.389-3.186,18.406-4.6a186.426,186.426,0,0,1,18.406-3.54,265.613,265.613,0,0,1,37.167-4.248c7.787-.354,15.929-.354,23.716-.354A366.847,366.847,0,0,0,67.254,228.2c-15.929,28.671-28.317,58.758-38.228,89.554A629.573,629.573,0,0,0,0,507.483C0,571.2,9.557,635.265,29.379,695.793,49.555,756.321,80,812.956,120,862.157a5.493,5.493,0,0,0,7.433,1.062,5.213,5.213,0,0,0,1.062-7.433h0A508.578,508.578,0,0,1,47.432,690.837C30.8,631.725,24.424,569.781,26.9,508.9A589.366,589.366,0,0,1,61.944,330.145c10.619-28.317,23.362-55.927,38.936-81.412a304.727,304.727,0,0,1,33.273-45.308c0,7.079,0,13.8.354,20.884.708,12.743,1.416,25.132,2.832,37.52s2.832,24.778,4.956,37.167a6.023,6.023,0,0,0,5.309,4.955C150.082,302.89,152.914,300.412,152.914,297.226Z" transform="translate(0 385.788)" />
					<g transform="translate(250)" fill={backgroundColor} stroke={frameBody} strokeLinejoin="round" strokeWidth="10">
						<rect width="1010" height="1010" rx="40" stroke="none"/>
						<rect x="-5" y="-5" width="1020" height="1020" rx="45" fill="none"/>
					</g>
				</g>
				<g transform="translate(252.3 16.6)"><BaseQrcode /></g>
				<g transform={"rotate(-5) translate(220 1100) scale(0.7)"}>
					<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
						<rect width={1000} height={200} fill={'none'} />
						<g transform={scale ? `scale(${scale})`: undefined} fill={frameBody} dangerouslySetInnerHTML={{ __html: text }}/>
					</svg>
				</g>
			</g>
		</svg>
	</>)