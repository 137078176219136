import React from "react";
import { QRCode } from '../qrcode'
import PointBase from "./PointBase";
import { pointOptions } from "./ultil";


/**
 * @QrProvider(
 *   id = "cross",
 *   title = @Translation("Cross")
 * )
 */
export class RendererCross extends PointBase {
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		
		const pointRender = ($options: any) => {
			let $opts = pointOptions($options, ["opacity", "fill", "transform"], [],
				function ($opts: any){
					let { "x": $x, "y": $y, "scale" : $scale } = $opts || {};
					$scale = $scale * 15;
					return Object.assign($opts, { "transform": `translate(${$x},${$y}) scale(${$scale})` });
				});
			return <g {...$opts}><polygon points="6,1.5 4.5,1.5 4.5,0 1.5,0 1.5,1.5 0,1.5 0,4.5 1.5,4.5 1.5,6 4.5,6 4.5,4.5 6,4.5"/></g>;
		}
		
		Object.assign($options, {
			"pos": pointRender,
			"align": pointRender,
			"extend": 1,
			"size": 100,
		})
		super($options, $qrcode, $name || 'RendererCross');
	}
	
}
