import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame12', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1083 1637">
		<g>
			<path fill={frameBody} d="M1034.676,1082.8H743.523a24.063,24.063,0,0,0-16.844,7.219L558.244,1258.455a22.859,22.859,0,0,1-33.687,0L356.121,1090.019a24.062,24.062,0,0,0-16.844-7.219H48.124A48.124,48.124,0,0,1,0,1034.676V48.124A48.124,48.124,0,0,1,48.124,0h986.552A48.124,48.124,0,0,1,1082.8,48.124v986.552A48.124,48.124,0,0,1,1034.676,1082.8Z" transform="translate(0 0)"/>
			<path fill={backgroundColor} d="M966.489,990.552H692.18a34.889,34.889,0,0,0-25.265,10.828L505.7,1162.6a12.031,12.031,0,0,1-16.844,0L327.637,1001.38a34.89,34.89,0,0,0-25.265-10.828H28.062A24.062,24.062,0,0,1,4,966.489V28.062A24.062,24.062,0,0,1,28.062,4H966.489a24.062,24.062,0,0,1,24.062,24.062V966.489A24.062,24.062,0,0,1,966.489,990.552Z" transform="translate(44.124 44.124)" />
			<rect fill={frameBody} width="1083" height="313" rx="40" transform="translate(0 1324)"/>
		</g>
		<g transform={"translate(43 41)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g transform={"translate(43,1382) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)