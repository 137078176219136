import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame23',({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 2415 1076">
		<g>
			<rect width="1077" height="1076" rx="40" transform="translate(1320)" stroke={frameBody} strokeWidth={20} fill={backgroundColor}/>
			<path fill={frameBody} d="M1030.362,24H52.5A52.5,52.5,0,0,0,0,76.5V417.769a52.5,52.5,0,0,0,52.5,52.5h977.859a78.754,78.754,0,0,0,56.44-23.626l181.133-181.134a24.938,24.938,0,0,0,0-36.752L1086.8,47.626A78.754,78.754,0,0,0,1030.362,24Z" transform="translate(0 291.015)"/>
			<rect width="172" height="290" rx="2" transform="translate(78 393)" fill={textColor}/>
			<rect fill={frameBody} width="146" height="210" transform="translate(91 433)"/>
			<path d="M100.879,76.377l-6.563-6.563L61.5,102.628V37H48.377v65.628L15.563,69.814,9,76.377l45.94,45.94Zm-13.126,52.5v13.126H22.126V128.879H9v26.251h91.879V128.879Z" transform="translate(109.131 448.648)" fill={textColor}/>
		</g>
		
		<g transform={"translate(1356 50)"}><BaseQrcode /></g>
		<g transform={"translate(282 453) scale(0.848)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)