import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";

/**
 * @QrProvider(
 *   id = "japanese",
 *   title = @Translation("Japanese"),
 *   type = 3
 * )
 */
export class RendererJapanese extends EdgeCutsBase{
	
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		super({...$options, type: 3 }, $qrcode, $name || 'RendererJapanese')
	}
}
