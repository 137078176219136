import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame17', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=> <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1121.206 1832.215">
		<g>
			<rect width="273" height="27" rx="1" transform="translate(424 123)" fill={backgroundColor}/>
			<g>
				<path d="M957.127,561.681H164.079A164.079,164.079,0,0,1,0,397.6V178.83H441.646a21.878,21.878,0,0,0,16.408-5.469l94.345-71.1a17.775,17.775,0,0,1,16.408,0l94.345,71.1a21.878,21.878,0,0,0,16.408,5.469h441.646V397.6A164.079,164.079,0,0,1,957.127,561.681Z" transform="translate(0 1270.534)" fill={frameBody}/>
				<path d="M1121.206,273.465H0V164.079A164.079,164.079,0,0,1,164.079,0H957.127a164.079,164.079,0,0,1,164.079,164.079Z" fill={frameBody}/>
			</g>
			<rect width="1121" height="1176" transform="translate(0 273)" fill={backgroundColor}/>
		</g>
		
		<g transform={"translate(61 352)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g transform={"translate(61 1535) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)