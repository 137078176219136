import React from "react";
import optionCallback from "./optionCallback";

export default optionCallback('frame22', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 2208.012 1069.567">
		<g>
			<path fill={frameBody} d="M2160.011,0h-984c-26.51,0-48,21.283-48,47.536V320.87a10.633,10.633,0,0,1-3.087,8.826,10.864,10.864,0,0,1-8.913,3.058H48c-26.51,0-48,21.283-48,47.536V689.277c0,26.253,21.49,47.536,48,47.536H1116.006a10.863,10.863,0,0,1,8.913,3.058,10.633,10.633,0,0,1,3.087,8.826v273.334c0,26.253,21.491,47.536,48,47.536h984c26.51,0,48-21.283,48-47.536V47.536C2208.012,21.283,2186.521,0,2160.011,0Z" transform="translate(0)"/>
			<rect  fill={backgroundColor} width="994" height="974" rx="20" transform="translate(1166 48)"/>
		</g>
		<g transform={"translate(1161 35)"}><BaseQrcode /></g>
		<g transform={"translate(59 435) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)