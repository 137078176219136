import React from "react";
import optionCallback from "./optionCallback";

export default optionCallback('frame5', ({ props, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor, shadowDark, shadowDarker, scale })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1322.414 1717.844">
		<g transform={"translate(162,63)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g>
			<path fill={frameBody} id="frameBody" data-name="Path 1" d="M1290.246,294.166,1208.257,221.2V424.015L797.136,626.045l-101.6-57.275a69.892,69.892,0,0,0-69.043,0l-101.6,57.275L114.157,424.015V221.2L32.168,294.166A101.586,101.586,0,0,0,0,367.917v653.95A49.473,49.473,0,0,0,49.429,1071.3H1272.985a49.473,49.473,0,0,0,49.429-49.429V367.917A101.586,101.586,0,0,0,1290.246,294.166Z" transform="translate(0 646.548)"/>
			<path fill={frameBody} id="Outline" d="M34.946,867.748V73.358c0-36.091,24.322-65.513,53.744-65.513h987.005c29.422,0,53.744,29.422,53.744,65.513v794.39l7.846,7.061V73.358C1136.892,32.952,1109.432,0,1075.3,0H88.69C54.56,0,27.1,32.952,27.1,73.358V874.81Z" transform="translate(79.211)"/>
			<path fill={'#fff'} opacity={0.7} id="Outline-2" data-name="Outline" d="M34.946,867.748V73.358c0-36.091,24.322-65.513,53.744-65.513h987.005c29.422,0,53.744,29.422,53.744,65.513v794.39l7.846,7.061V73.358C1136.892,32.952,1109.432,0,1075.3,0H88.69C54.56,0,27.1,32.952,27.1,73.358V874.81Z" transform="translate(79.211)" />
			<path fill={shadowDark} opacity={0.15} id="ShadowDark" d="M1208.257,367.917V221.2l81.989,72.966a101.586,101.586,0,0,1,32.168,73.751H1208.257Zm-1094.493,0V221.2l-81.6,72.966A101.586,101.586,0,0,0,0,367.917Zm1094.493,36.091L799.1,604.469l-99.25-56.49a78.383,78.383,0,0,0-77.674,0l-99.25,56.49L114.157,404.008v20.007L503.7,615.845h0L315.795,743.732,626.489,568.77a69.892,69.892,0,0,1,69.043,0L1006.62,743.732,818.712,615.845l389.545-191.83V404.008Z" transform="translate(0 646.548)"/>
			<path fill={shadowDarker} opacity={0.7} id="shadowDarker" d="M114.157,294.691V314.7l-.785-.392L0,258.6H114.157Zm1094.1,0V314.7l.785-.392L1322.414,258.6H1208.257Z" transform="translate(0 755.865)"/>
		</g>
		<g transform={"translate(160,1416)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)