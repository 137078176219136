import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame9', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1903.089 1148.097">
		<g transform={"translate(722,28)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g fill={frameBody}>
			<path
				d="M1070,0H140.457A65.588,65.588,0,0,0,76,64.457V994a65.588,65.588,0,0,0,64.457,64.457H1070A64.457,64.457,0,0,0,1134.455,994V64.457A64.457,64.457,0,0,0,1070,0Zm13.57,978.166a32.794,32.794,0,0,1-32.794,31.663H154.027a32.794,32.794,0,0,1-32.794-31.663V80.289a31.663,31.663,0,0,1,32.794-31.663h896.747a31.663,31.663,0,0,1,32.794,31.663Z"
				transform="translate(620.044)"/>
			<path
				d="M600.8,221.2c-7.916-38.448-76.9-45.233-105.167-49.756-9.047-2.262-27.14-3.393-35.056,3.393s-11.308,7.916,1.131,10.178,61.065,6.785,79.158,26.009c-125.522,38.448-263.483,41.841-382.22-30.532a400.315,400.315,0,0,1-92.728-76.9L44.434,76.449c-3.392-4.523-7.916-12.439-5.654-7.916C25.21,44.786,4.855,57.225,19.556,80.972c115.344,194.5,341.51,226.166,525.835,176.409-11.308,31.663-41.841,61.065-64.457,80.289-5.654,4.523,18.093,6.785,19.224,6.785a41.841,41.841,0,0,0,30.532-6.785C557.83,313.923,608.717,265.3,600.8,221.2Z"
				transform="translate(-14.448 574.908)"/>
		</g>
		<g transform={"translate(0,451)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={680} height={200} viewBox={viewBox}>
				<rect width={'100%'} height={200} fill={'none'}/>
				<g transform={`scale(${1 * scale})`} fill={frameBody} dangerouslySetInnerHTML={{__html: text}}/>
			</svg>
		</g>
	</svg>)