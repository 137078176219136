import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame18',({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=> <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1380.918 1090.198">
		<g>
			<g>
				<g>
					<path d="M1041.745,1090.2H48.453A48.453,48.453,0,0,1,0,1041.745V48.453A48.453,48.453,0,0,1,48.453,0h993.292A48.453,48.453,0,0,1,1090.2,48.453V169.586a10.9,10.9,0,0,0,12.113,12.113h230.153a48.453,48.453,0,0,1,48.453,48.453V666.232a48.453,48.453,0,0,1-48.453,48.453H1102.312A10.9,10.9,0,0,0,1090.2,726.8v314.946A48.453,48.453,0,0,1,1041.745,1090.2Z" transform="translate(0 0)" fill={frameBody}/>
					<rect width="993" height="993" rx="20" transform="translate(49 49)" fill={backgroundColor}/>
				</g>
				<rect width="217" height="411" rx="20" transform="translate(1103 243)" fill="#fff"/>
				<g transform="translate(1114.764 266.466)">
					<rect width="193" height="290" transform="translate(0.236 24.534)" fill={frameBody}/>
					<rect width="48" height="13" transform="translate(73.236 -0.466)" fill={frameBody}/>
					<rect width="36.34" height="36.34" rx="15" transform="translate(78.397 333.144)" fill={frameBody}/>
				</g>
				<path d="M204.02,79.453,191.907,67.34,167.68,91.567V31H143.453V91.567L119.227,67.34,107.113,79.453l48.453,48.453Zm0,48.453v24.227H107.113V127.907H95v36.34H216.133v-36.34Z" transform="translate(1055.765 344.513)" fill="#fff"/>
			</g>
		</g>
		
		<g transform={"translate(54 54) scale(0.98)"}>
			<rect width="1000" height="1000" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g transform={"translate(61 1535) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)