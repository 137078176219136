import React from "react";
import Field from "@gqlapp/forms-client-react/FieldAdapter";
import RenderField from "@xem/look/Renders/Field";
import { Banks } from 'vietnam-qr-pay';
import RenderArrow from "@xem/look/Renders/Field/Arrow";
import RenderPrice from "@xem/look/Renders/Field/Price";
import Group from "@gqlapp/base/utils/Group";
import BaseConfig from '@xem/look/utils/fieldConfig';
import Image from "@gqlapp/base/Image";

const BaseForm = ((props) => {
	let { tabIndex, values, setFieldValue, onActions } = props;
	let prefix = null, { content } = values;

	let bankName = Banks.filter(({ bin })=> content?.bankBin === bin)?.shift()?.shortName;

	function openPopup(name, values){
		return ()=>{
			func(onActions[name])(values);
		}
	}

	function fieldConfig ({ name, value, component, inputProps, ...props }) {
		return BaseConfig({
			...{ component: component || RenderField, inputProps: { ...inputProps, tabIndex: tabIndex++ } },
			label: `page:block.bankQr.${name}.label`.t(),
			placeholder: `page:block.bankQr.${name}.placeholder`.t(),
			setFieldValue, name, value, ...props
		})
	}

	const sx = {
		padding: '0 15px 15px', backgroundColor: '#ffffff',
		'& .MuiFormControl-root':{ marginTop: '8px', marginBottom: 0, minHeight: '54px' },
		'& >.row':{
			paddingTop: '15px',
			'& > .col':{ alignItems: 'center', alignContent: 'center' }
		}
	}


	let group1 = {
		...sx,
		paddingleft: '15px',
		paddingRight: '15px',
		paddingBottom: '0px',
		'& .MuiFormGroup-root':{ minHeight: '60px' },
	}

	if(content?.bankBin){
		let bank = Banks.filter(({ bin })=> `${bin}` === `${content?.bankBin}`).shift();
		// debug(content?.bankBin, bank)
		if(bank && bank.key){
			let path = `${bank.key}`.replace('_', '-')
			let src = `https://cdn.xem.link/public/icons/bank/${path}.webp`;
			prefix = <Image
				{...{ src, autoSize: "40:40" }}
				style={{ objectFit: 'scale-down', width: '100%', height: '100%' }}
			/>
		}

	}

	return (<>
		<Group sx={group1} >
			<Field
				{...fieldConfig({
					component: RenderArrow,
					prefix, name: `content.bankBin`, value: bankName,
					onClick: openPopup('bank', {
						value: content?.bankBin,
						onChange: function onChange(value, { onClose }){
							setFieldValue('content.bankBin', value);
							func(onClose)();
						}
					}),
				})}
			/>

			<Field
				{...fieldConfig({
					label: `page:block.bankQr.content.bankNameAccount.label`.t(),
					placeholder: `page:block.bankQr.content.bankNameAccount.placeholder`.t(),
					name: `frame.text`,
					value: values?.frame?.text
				})}
			/>

			<Field
				{...fieldConfig({
					name: `content.bankNumber`,
					value: content?.bankNumber
				})}
			/>

			<Field
				{...fieldConfig({
					name: `content.amount`,
					value: content?.amount,
					inputProps: { inputMode: 'numeric', min: 0 },
					component: RenderPrice,
					prefix: 'VND'
				})}
			/>
			<Field
				{...fieldConfig({
					name: `content.purpose`,
					value: content?.purpose
				})}
			/>
		</Group>
	</>)
})


export default BaseForm;