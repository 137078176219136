import React from "react";
import optionCallback from "./optionCallback";
export default optionCallback('frame7', ({ props, scale, backgroundColor, BaseQrcode, frameBody,  viewBox, text, textColor })=>
	<svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 1080 1400">
		<g><rect fill={frameBody} width="1080" height="1400" rx="40"/></g>
		<g transform={"translate(40,40)"}>
			<rect width="1000" height="1000" rx="20" fill={backgroundColor}/>
			<BaseQrcode />
		</g>
		<g transform={"translate(40,1128) scale(1)"}>
			<svg xmlns="http://www.w3.org/2000/svg" width={1000} height={200} viewBox={viewBox}>
				<rect width={1000} height={200} fill={'none'} />
				<g transform={scale ? `scale(${scale})`: undefined} fill={textColor} dangerouslySetInnerHTML={{ __html: text }}/>
			</svg>
		</g>
	</svg>)