import { QRCode } from '../qrcode'
import EdgeCutsBase from "./EdgeCutsBase";
/**
 * @QrProvider(
 *  id = "round_in_smooth",
 *  title = @Translation("RoundInSmooth"),
 * 	type = 12
 * )
 */
export class RendererRoundInSmooth extends EdgeCutsBase{
	/**
	 * constructor
	 * @param $options
	 * @param $qrcode
	 * @param $name
	 */
	constructor($options: any = {}, $qrcode: QRCode, $name?: string) {
		super({...$options, type: 12 }, $qrcode, $name || 'RendererRoundInSmooth')
	}
}
